var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fb-pc-content" }, [
    JSON.stringify(_vm.goodsData) != "{}"
      ? _c("div", [
          _c("h3", { staticClass: "fb-title" }, [_vm._v("纠错商品")]),
          _c("div", { staticClass: "fb-goods-des" }, [
            _c("img", {
              staticClass: "fbg-img",
              attrs: { src: _vm.goodsData.mainPic, alt: "" }
            }),
            _c("div", { staticClass: "fbg-des" }, [
              _c("div", [
                _vm._v(_vm._s(_vm.goodsData.dtitle || _vm.goodsData.title))
              ]),
              _c("p", { staticClass: "des" }, [
                _vm._v("描述：" + _vm._s(_vm.goodsData.desc))
              ]),
              _c("p", [_vm._v("原价：" + _vm._s(_vm.goodsData.originalPrice))]),
              _vm.goodsData.couponPrice > 0
                ? _c("p", [
                    _vm._v(
                      " 优惠券：" + _vm._s(_vm.goodsData.couponPrice) + " "
                    )
                  ])
                : _vm._e(),
              _vm.goodsData.actualPrice > 0
                ? _c("p", [
                    _vm._v(
                      " 券后价：" + _vm._s(_vm.goodsData.actualPrice) + " "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e(),
    _c("h3", { staticClass: "fb-title" }, [_vm._v("错误类型")]),
    _c(
      "ul",
      { staticClass: "fb-error-type" },
      _vm._l(_vm.errorInfo, function(item, index) {
        return _c(
          "li",
          {
            key: item.err_type,
            class: { cur: index === _vm.isShow },
            on: {
              click: function($event) {
                return _vm.handleShow(index, item.err_type)
              }
            }
          },
          [_c("span"), _vm._v(" " + _vm._s(item.content) + " ")]
        )
      }),
      0
    ),
    _c("div", { staticStyle: { clear: "both" } }),
    _c("h3", { staticClass: "fb-title" }, [_vm._v("错误描述")]),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.textArea,
          expression: "textArea"
        }
      ],
      staticClass: "fb-pc-text",
      attrs: {
        name: "content",
        maxlength: "300",
        placeholder: "请在此输入您的反馈意见",
        rows: "10"
      },
      domProps: { value: _vm.textArea },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.textArea = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("p", [_vm._v("感谢您的反馈，我们会尽快处理")]),
    _c(
      "button",
      {
        class: "fb-sub " + (_vm.textArea.length > 0 ? "" : "disabled"),
        attrs: { disabled: _vm.textArea.length > 0 ? false : true },
        on: { click: _vm.sendReports }
      },
      [_vm._v(" 提交 ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }