<template>
  <div class="fb-pc-content">
    <div v-if="JSON.stringify(goodsData) != '{}'">
      <h3 class="fb-title">纠错商品</h3>
      <div class="fb-goods-des">
        <img :src="goodsData.mainPic" class="fbg-img" alt="" />
        <div class="fbg-des">
          <div>{{ goodsData.dtitle || goodsData.title }}</div>
          <p class="des">描述：{{ goodsData.desc }}</p>
          <p>原价：{{ goodsData.originalPrice }}</p>
          <p v-if="goodsData.couponPrice > 0">
            优惠券：{{ goodsData.couponPrice }}
          </p>
          <p v-if="goodsData.actualPrice > 0">
            券后价：{{ goodsData.actualPrice }}
          </p>
        </div>
      </div>
    </div>
    <h3 class="fb-title">错误类型</h3>
    <ul class="fb-error-type">
      <li
        :class="{ cur: index === isShow }"
        v-for="(item, index) in errorInfo"
        :key="item.err_type"
        @click="handleShow(index, item.err_type)"
      >
        <span></span>
        {{ item.content }}
      </li>
    </ul>
    <div style="clear: both"></div>
    <h3 class="fb-title">错误描述</h3>
    <textarea
      class="fb-pc-text"
      name="content"
      v-model="textArea"
      maxlength="300"
      placeholder="请在此输入您的反馈意见"
      rows="10"
    />
    <p>感谢您的反馈，我们会尽快处理</p>
    <button
      :class="`fb-sub ${textArea.length > 0 ? '' : 'disabled'}`"
      @click="sendReports"
      :disabled="textArea.length > 0 ? false : true"
    >
      提交
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorInfo: [
        {
          err_type: 1,
          content: "价格/标题/文案/图片与店铺描述不符",
        },
        { err_type: 2, content: "优惠券无法正常使用" },
        { err_type: 3, content: "其他问题" },
      ],
      errType: 1,
      isShow: 0,
      textArea: "",
      goodsData: {},
    };
  },
  mounted() {
    this.getDetaiData();
    if (this.$route.query.type) {
      this.errType = this.$route.query.type;
      this.isShow = this.$route.query.type - 1;
    }
  },
  methods: {
    handleShow(item, type) {
      this.isShow = item;
      this.errType = type;
    },
    getDetaiData() {
      const id = this.$route.query.id;
      if (id?.length <= 10) {
        this.$services
          .getTbDetails({ ...(id.length === 8 ? { id: id } : { goodsId: id }) })
          .then((res) => {
            if (res && res.code === 0) {
              this.goodsData = res.data;
            }
          });
      }
    },
    sendReports() {
      let params = {
        gid: this.$route.query.id,
        err_type: this.errType,
        content: this.textArea,
      };
      this.$services.sendReports({ ...params }).then((res) => {
        if (res && res.code === 1) {
          this.$modal.Toast(`感谢您的反馈！<br />我们会尽快进行处理`, 1500);
          this.$router.back();
        } else {
          this.$modal.Toast(res.msg, 1500);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fb-pc-content {
  width: 950px;
  height: 650px;
  border: 1px solid transparent;
  margin: 20px auto 50px;
  background-color: #fff;
  padding: 0 25px;
  .fb-title {
    color: #333;
    font-family: "Microsoft yahei";
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .fb-goods-des {
    height: 130px;
    background-color: #faf5f8;
    .fbg-img {
      width: 100px;
      height: 100px;
      display: inline-block;
      margin: 15px;
      float: left;
    }
    .fbg-des {
      float: left;
      margin: 10px 15px 15px 5px;
      color: #555;
      div {
        width: 786px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .des {
        width: 786px;
        overflow: hidden;
        height: 40px;
      }
    }
  }
  .fb-error-type {
    li {
      border: 1px solid #ddd;
      color: #555;
      font-size: 14px;
      padding: 10px 25px;
      float: left;
      border-radius: 2px;
      position: relative;
      cursor: pointer;
      margin-right: 20px;

      &.cur span {
        border: 2px solid #ed145b;
      }

      &.cur::after {
        content: "";
        width: 22px;
        height: 22px;
        background: url(https://cmsstaticnew.dataoke.com//images/shoufa/cms_pic-recourses.png?v=201712221028) -233px -24px
          no-repeat;
        right: -1px;
        bottom: -1px;
        position: absolute;
      }
      span {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -1px;
        top: -1px;
        &:hover {
          border: 2px solid #ed145b;
        }
      }
    }
  }
  .fb-pc-text {
    width: 920px;
    height: 100px;
    padding: 15px;
    color: #333;
    font-family: "Microsoft yahei";
    border-color: #ddd;
    font-size: 14px;
  }
  .fb-sub {
    width: 100px;
    height: 35px;
    border-radius: 4px;
    background-color: #ed145b;
    color: #fff;
    font-size: 16px;
    border: none;
    font-family: "Microsoft yahei";
    margin: 0 auto;
    display: block;
    cursor: pointer;
    text-align: center;
    line-height: 35px;
    &.disabled {
      width: 100px;
      height: 35px;
      border-radius: 4px;
      background-color: #ccc;
      color: #fff;
      font-size: 16px;
      border: none;
      font-family: "Microsoft yahei";
      margin: 0 auto;
      display: block;
      cursor: pointer;
      text-align: center;
      line-height: 35px;
    }
  }
}
</style>